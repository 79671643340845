const static_pages = (function(){

  function initTreadmillPartsListeners(){
    let tablist = document.querySelectorAll(".parts_list li a");
    for(let i = 0; i < tablist.length; i++){
      tablist[i].addEventListener("click", function(e){
        if( !e.target.classList.contains("active") ){
          document.querySelector(".parts_list li a.active").className = "";
          e.target.className = "active";
        }

        let id = e.target.id;
        let associatedModels = document.querySelectorAll(".parts_m_number div");
        for(let i = 0; i < associatedModels.length; i++){
          associatedModels[i].style.display = "none";
        }
        document.getElementById('sort_list' + id).style.display = "block";
      }, false);
    }


    //mobile

    let mobileTabList = document.querySelectorAll(".parts_m_number h5");
    for(let i = 0; i < mobileTabList.length; i++){

      mobileTabList[i].addEventListener("click", function(e){
        let uls = document.querySelectorAll(".parts_m_number ul");
        let nextUl = e.target.nextSibling;
        for(let x = 0; x < uls.length; x++){
          if(uls[x] !== nextUl){
            uls[x].style.display = "none";
          }
        }

        (nextUl.style.display == "block") ? (nextUl.style.display = "none") : (nextUl.style.display = "block");

      }, false);

    }

    window.addEventListener("resize", function() {

      let uls = document.querySelectorAll(".parts_m_number ul");
      if ( window.outerWidth < 640) {
        for(let i = 0; i < uls.length; i++){
          uls[i].style.display = "none";
        }

        let divs = document.querySelectorAll(".parts_m_number div");
        for(let i = 0; i < divs.length; i++){
          divs[i].style.display = "block";
        }

      }
     else {
       for(let i = 0; i < uls.length; i++){
         uls[i].style.display = "block";
       }
     }
    }, false);

  }//end initTreadmillPartsListeners


  function initBestBuyListeners(){

    //start bestbuyawards
    let runners = document.getElementsByClassName("show_runners");
    for(let i = 0; i < runners.length; i++){
      runners[i].addEventListener("click", function(e){
        e.preventDefault();

        let bbb = e.target.closest(".winner_wrap");
        let slidable = bbb.querySelector(".column.hidden-on-mobile");

        if(slidable.clientHeight === 0){
          slidable.style = "max-height: 1000px;";
        }else{
          slidable.style = "max-height: 0px;"
        }

      }, false);
    }
  }//end initBestBuyListeners

  function initHelpYtVideoLazyLoad() {
    const targetDiv = document.querySelector('#render-repair-yt');
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const videoSupportElement = document.querySelector('.video_support');
        if (entry.isIntersecting && videoSupportElement === null) {
          document.querySelector('#render-repair-yt').innerHTML = '<iframe class="video_support" src="https://www.youtube.com/embed/QHfgMrpMOm4" allow="autoplay; encrypted-media" loading="lazy" allowfullscreen=""></iframe>';
        }
      });
    });

    if (targetDiv) {
      observer.observe(targetDiv);
    }
  }//end initHelpYtVideoLazyLoad

  function initResellerRatingsLoad() {
    const targetDiv = document.querySelector('#tmd_rr_box_horizontal');
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && targetDiv.innerHTML == '') {
          const script = document.createElement('script');
          script.src = 'https://api.resellerratings.com/assets/script/86279fa050d97a48a626884bf859d2e3c';
          script.defer = true;
          document.querySelector('#tmd_rr_box_horizontal').appendChild(script);
        }
      });
    });

    if (targetDiv) {
      observer.observe(targetDiv);
    }
  }//end initResellerRatingsLoad

  return {
    init: function(){
      initTreadmillPartsListeners();
      initBestBuyListeners();
      initHelpYtVideoLazyLoad();
      initResellerRatingsLoad();

      // initAutoSearch(document.getElementById('search'), document.getElementById('autocomplete-results'));
      // initAutoSearch(document.getElementById('mb-search'), document.getElementById('mb-autocomplete-results'));
    }
  }



})();
export {static_pages};
